<template>
  <div class="billing-history">
    <div class="billing-history__head">
      <div class="billing-docs__nav">
        <tabs :list="nav" theme="pills" class="billing-history__nav-tabs">
          <template v-slot:item="{ item }">
            <router-link exact-active-class="active" :to="item.to">
              {{ item.title }}
            </router-link>
          </template>
        </tabs>
      </div>
      <div class="billing-history__filter">
        <base-datepicker-filter
          :periods="periods"
          :period="period"
          :value="dateFilter"
          class="billing-history__filter-item"
          @init="onFilterInit"
          @change="onFilterChange"
          @change-period="onPeriodChange"
        />
      </div>
      <div class="billing-history__export">
        <plain-button v-bind="exportAttrs" color="primary" class="billing-history__export-btn">
          {{ $t('export') }}
        </plain-button>
      </div>
    </div>
    <div class="billing-history__content">
      <router-view :date-filter="dateFilter" />
    </div>
  </div>
</template>

<script>
import tabs from '@/components/Tabs/Tabs.vue';
import BaseDatepickerFilter from '@/components/BaseDatepicker/BaseDatepickerFilter.vue';
import { format } from 'date-fns';
import showErrorModal from '@/mixins/showErrorModal';

export default {
  name: 'BillingHistory',
  components: {
    tabs,
    BaseDatepickerFilter,
  },
  mixins: [showErrorModal],
  data() {
    return {
      nav: [
        {
          title: this.$t('nav.summary'),
          to: { name: 'BillingHistorySummary' },
        },
        {
          title: this.$t('nav.details'),
          to: { name: 'BillingHistoryDetails' },
        },
      ],
    };
  },
  computed: {
    dateFilter() {
      return this.$store.state.moduleBilling.moduleHistory.dateFilter;
    },
    periods() {
      return this.$store.state.moduleBilling.moduleHistory.periods;
    },
    period() {
      return this.$store.state.moduleBilling.moduleHistory.period;
    },
    host() {
      return this.$store.state.moduleApp.host;
    },
    token() {
      return localStorage.getItem('token');
    },
    exportAttrs() {
      return {
        target: '_blank',
        href: `${this.host}/billmgr?out=csv&func=money_all&auth=${this.token}`,
      };
    },
  },
  methods: {
    setFilter(payload = {}) {
      const params = {
        summary: 'off',
        type: 'null',
        ...payload,
      };
      return this.$store
        .dispatch('moduleBilling/moduleHistory/setFilter', params)
        .catch(e => this.showError(e));
    },
    fetchList() {
      const params = {
        summary: 'off',
        type: 'null',
      };
      this.$store
        .dispatch('moduleBilling/moduleHistory/fetchExpenseList')
        .catch(e => this.showError(e));
      return this.$store
        .dispatch('moduleBilling/moduleHistory/fetchList', params)
        .catch(e => this.showError(e));
    },
    onFilterInit(value) {
      this.onFilterChange(value);
    },
    onFilterChange(value) {
      this.$store.commit('moduleBilling/moduleHistory/setDateFilter', value);
      const formatTpl = 'yyyy-LL-dd';
      const params = {
        date: 'other',
        datestart: format(this.dateFilter.start, formatTpl),
        dateend: format(this.dateFilter.end, formatTpl),
      };
      this.setFilter(params).then(() => setTimeout(this.fetchList, 200));
    },
    onPeriodChange(value) {
      this.$store.commit('moduleBilling/moduleHistory/setPeriod', value);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "export": "Экспортировать в CSV",
    "nav": {
      "details": "Подробно",
      "summary": "Сводка"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.billing-history {
  &__head {
    margin-bottom: 1.5rem;

    +breakpoint(sm-and-up) {
      flexy(flex-start, center, wrap);
    }
  }
  &__nav {
    flex: 1 0 100%;
    +breakpoint(lg-and-up) {
      flex: 0 0 auto;
    }
  }
  &__filter {
    margin: 1rem 0;

    +breakpoint(sm-and-up) {
      margin-right: auto;
    }
    +breakpoint(lg-and-up) {
      margin: 0 1rem;
      margin-right: auto;
    }
  }
  &__export {
    +breakpoint(sm-and-up) {
      margin: 1rem 0 1rem auto;
    }
  }
}
</style>

<style lang="scss">
.history {
  &-table {
    min-width: 1072px;
  }
  &-controllers {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    &-export {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      &__text {
        cursor: pointer;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */
        text-align: right;
        color: #0f68e4;
      }
    }
  }
}
</style>
